import { Grid, Typography } from '@mui/material';
import { FC } from 'react';

const NotAuthorized: FC = () => {
  return (
    <Grid
      container
      direction='column'
      alignItems='center'
      justifyContent='center'
      sx={{ minHeight: '100vh' }}>
      <Grid>
        <Typography variant={'h4'} align='center'>
          Access to this form has not been granted yet. Please, contact Enara Health.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default NotAuthorized;
