import axios, { AxiosInstance } from 'axios';
import {
  PatientSectionInfo,
  ReferringProviderSectionInfo,
  WhyReferringSectionInfo,
} from '../../Components/LeadCaptureForm/form.types';
import { AuthorizedPartnerForm, CreateLeadPayload } from './types';

class OnboardingClient {
  private readonly axiosInstance: AxiosInstance;

  constructor(baseURL: string) {
    if (!baseURL) {
      throw new Error('Onboarding client cannot be instantiated with an empty base URL');
    }

    this.axiosInstance = axios.create({ baseURL });
  }

  public async checkPartnerFormAuthorization(): Promise<AuthorizedPartnerForm | null> {
    const externalId = new URLSearchParams(window.location.search).get('externalId');

    try {
      const authorizedPartner = (
        await this.axiosInstance.get<AuthorizedPartnerForm>(
          '/onboarding/v2.0/partner-forms/config',
          { params: { externalId } }
        )
      ).data;

      return authorizedPartner;
    } catch (error) {
      console.error(error);
    }

    return null;
  }

  public async createLead({
    referringInfo,
    patientInfo,
    whyReferringInfo,
  }: {
    referringInfo: ReferringProviderSectionInfo;
    patientInfo: PatientSectionInfo;
    whyReferringInfo: WhyReferringSectionInfo;
  }): Promise<boolean> {
    const payload: CreateLeadPayload = {
      patient: {
        firstName: patientInfo.firstName,
        lastName: patientInfo.lastName,
        email: patientInfo.email,
        dateOfBirth: patientInfo.dateOfBirth,
        feet: +patientInfo.heightFeet,
        inches: +patientInfo.heightInches,
        weight: +patientInfo.weight,
        bmi: patientInfo.bmi,
      },
      provider: {
        firstName: referringInfo.firstName,
        lastName: referringInfo.lastName,
        email: referringInfo.email,
        organization: referringInfo.organization,
        isFirstTimeReferring: referringInfo.firstTimeReferring,
        whyReferring: {
          patientDetails: whyReferringInfo.patientDetails,
          prescribedMedicationChangesAllowed: whyReferringInfo.prescribedMedicationChangesAllowed,
          medicationChangesAllowed: whyReferringInfo.medicationChangesAllowed,
        },
      },
    };

    try {
      await this.axiosInstance.post<CreateLeadPayload>('/onboarding/v2.0/crm/leads', payload);

      return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }
}

const onboardingClient = new OnboardingClient(process.env.REACT_APP_ONBOARDING_API_BASE_URL!);

export default onboardingClient;
