import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { FC } from 'react';

type Props = {
  isOpen: boolean;
  content: string;
  onClose: () => void;
};

const CreateLeadPrompt: FC<Props> = ({ isOpen, content, onClose }) => {
  return (
    <Dialog open={isOpen}>
      <DialogTitle>{'Provider Referrals'}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button variant='contained' onClick={onClose} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateLeadPrompt;
