import { Button, CircularProgress, Paper, Stack, Typography } from '@mui/material';
import { FC, ReactNode, isValidElement, useEffect, useState } from 'react';
import { useViewport } from '../../hooks/useViewport';
import './scss/basicLayout.scss';

const KEYBOARD_HEIGHT = 300;
const TABLET_MIN_HEIGHT = 700;

type ButtonProps = {
  text?: string;
  disabled?: boolean;
  loading?: boolean;
  variant?: 'text' | 'outlined' | 'contained';
  inForm?: boolean;
  onClick: () => void;
};

type Props = {
  title: string | ReactNode;
  subtitle?: string | ReactNode;
  children: ReactNode;
  buttonProps?: ButtonProps;
};

const BasicLayout: FC<Props> = ({ title, subtitle, children, buttonProps }) => {
  const [isKeyboardOpen, setKeyboardOpen] = useState(false);

  const { isMobileView, isDesktopView, isTabletView } = useViewport();

  const handleClick = (): void => {
    buttonProps!.onClick();
  };

  useEffect(() => {
    const validateIfKeyboardIsOpen = () => {
      if (isDesktopView && isKeyboardOpen) {
        setKeyboardOpen(false);
      }

      if (isMobileView || isTabletView) {
        const isKeyboardDisplayed =
          window.screen.height - KEYBOARD_HEIGHT >= window.visualViewport!.height;

        if (isKeyboardOpen !== isKeyboardDisplayed) {
          setKeyboardOpen(
            window.visualViewport!.height >= TABLET_MIN_HEIGHT ? false : isKeyboardDisplayed
          );
        }
      }
    };

    window.visualViewport!.addEventListener('resize', validateIfKeyboardIsOpen);

    return () => {
      window.visualViewport!.removeEventListener('resize', validateIfKeyboardIsOpen);
    };
  }, [isDesktopView, isMobileView, isTabletView, isKeyboardOpen]);

  return (
    <Stack justifyContent={'stretch'} alignItems={'center'} className={'wrapper-basic-layout'}>
      <Paper elevation={0} className='container-basic-layout'>
        <Stack justifyContent={'stretch'} alignItems={'center'}>
          <Paper
            elevation={0}
            square
            className='title-basic-layout'
            style={subtitle ? { paddingBottom: 28 } : { paddingBottom: 16 }}>
            {isValidElement(title) ? title : <Typography variant={'h1'}>{title}</Typography>}
            {isValidElement(subtitle) ? (
              subtitle
            ) : (
              <Typography variant={'h4'}>{subtitle}</Typography>
            )}
          </Paper>
          <Paper elevation={0} square className='line-basic-layout'>
            {children}

            {buttonProps && !!buttonProps.inForm && (
              <Button
                name='Next Button'
                variant='contained'
                onClick={handleClick}
                disabled={buttonProps.disabled || buttonProps.loading}
                className='inline-action-button'
                sx={{ marginTop: '54px' }}>
                {buttonProps.loading ? (
                  <CircularProgress color='primary' />
                ) : (
                  buttonProps.text || 'Next'
                )}
              </Button>
            )}
          </Paper>
        </Stack>
      </Paper>
      {buttonProps && !buttonProps.inForm && (
        <Paper
          elevation={0}
          square
          className={'bottom-bar-basic-layout w-full'}
          sx={{
            position: (isMobileView || isTabletView) && isKeyboardOpen ? 'absolute' : 'fixed',
          }}>
          {buttonProps && (
            <Button
              name='Next Button'
              variant='contained'
              onClick={handleClick}
              disabled={buttonProps.disabled || buttonProps.loading}>
              {buttonProps.loading ? (
                <CircularProgress color='primary' />
              ) : (
                buttonProps.text || 'Next'
              )}
            </Button>
          )}
        </Paper>
      )}
    </Stack>
  );
};

export default BasicLayout;
