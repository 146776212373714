import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { FC, useEffect, useState } from 'react';
import onboardingClient from '../../lib/onboarding/client';
import LeadCaptureForm from '../LeadCaptureForm/LeadCaptureForm';
import NotAuthorized from '../NotAuthorized/NotAuthorized';
import PreparingForm from '../PreparingForm/PreparingForm';

const App: FC = () => {
  const [formStatus, setFormStatus] = useState<
    'preparing-form' | 'not-authorized' | 'access-granted'
  >('preparing-form');

  useEffect(() => {
    (async () => {
      const authorizedPartner = await onboardingClient.checkPartnerFormAuthorization();

      if (authorizedPartner) {
        setFormStatus('access-granted');
      } else {
        setFormStatus('not-authorized');
      }
    })();
  }, []);

  return formStatus === 'preparing-form' ? (
    <PreparingForm />
  ) : formStatus === 'not-authorized' ? (
    <NotAuthorized />
  ) : (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <LeadCaptureForm />
    </LocalizationProvider>
  );
};

export default App;
