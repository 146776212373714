import { useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';

export const useViewport = () => {
  const isDesktopView = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const isTabletView = useMediaQuery((theme: Theme) => theme.breakpoints.between('sm', 'lg'));
  const isMobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return {
    isDesktopView,
    isTabletView,
    isMobileView,
  };
};
