const MAGIC_NUMBER_TO_COMPUTE_BMI = 703;

export const isEmailValid = (email: string) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);

export const isZipCodeValid = (zipCode: string) => /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipCode);

export const isHeightFeetValid = (value: string) => +value >= 1 && +value <= 10;

export const isHeightInchesValid = (value: string) =>
  value.length > 0 && +value >= 0 && +value <= 11;

export const isWeightLbsValid = (value: string) => +value >= 10 && +value <= 999;

export const computeBMI = ({
  weight,
  feet,
  inches,
}: {
  weight: string;
  feet: string;
  inches: string;
}): number => {
  const height = Number(feet || 0) * 12 + Number(inches || 0);

  return (
    parseFloat(
      ((Number(weight || 0) * MAGIC_NUMBER_TO_COMPUTE_BMI) / (height * height)).toFixed(2)
    ) || 0
  );
};
