import { createTheme } from '@mui/material/styles';

const StyledTheme = {
  black: '#424242',

  blueSapphire: '#278ba5',
  aliceBlue: '#dee5ec',
  lightBlue: '#aec8ce',
  white: '#ffffff',
  whiteSmoke: '#f9f9f9',
  cultured: '#f4f7f4',
};

const palette = {
  primary: {
    dark: '#134c5b',
    light: '#278ba5',
    main: '#278ba5',
  },
  // Negative
  error: {
    dark: '#ff2800',
    light: '#ffd1d1',
    main: '#ff5535',
  },
  // Neutral
  info: {
    dark: '#424242',
    light: '#bdbdbd',
    main: '#6f6d6b',
  },
};

const theme = createTheme({
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 320,
      sm: 480,
      md: 768,
      lg: 960,
      xl: 1024,
    },
  },
  palette,
  typography: {
    fontFamily: ['Proxima Nova', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    h1: {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: '32px',
      color: palette.info.dark,
    },
    h4: {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: '28px',
      color: palette.info.dark,
    },
    h6: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '20px',
      color: palette.info.dark,
    },
    subtitle1: {
      color: StyledTheme.black,
      fontSize: 16,
      fontWeight: 700,
    },
    button: {
      fontWeight: 700,
      fontSize: 20,
    },
  },
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: `${StyledTheme.aliceBlue}`,
          padding: 0,
          paddingRight: 10,

          '&.Mui-checked': {
            color: `${StyledTheme.blueSapphire}`,
          },

          '&:hover': {
            backgroundColor: 'unset',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          boxShadow: 'unset',
          minWidth: 320,
          padding: 8,
          borderRadius: 4,

          '&:hover': {
            boxShadow: 'unset',
          },

          '@media (max-width: 380px)': {
            minWidth: 300,
          },

          '@media (max-width: 300px)': {
            minWidth: 200,
          },

          '&.Mui-disabled': {
            color: StyledTheme.white,
            backgroundColor: StyledTheme.lightBlue,
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          color: StyledTheme.black,
          background: StyledTheme.whiteSmoke,
          borderRadius: 4,
          border: `1px solid ${StyledTheme.cultured}`,

          '&::before': {
            border: 'none',
          },

          '&:after': {
            border: `none`,
          },

          '&.Mui-error': {
            border: `1px solid ${palette.error.dark}`,
          },

          '&.Mui-disabled': {
            background: StyledTheme.whiteSmoke,
          },

          '&.Mui-disabled:before': {
            borderBottom: 'none',
          },

          '&:hover': {
            background: StyledTheme.whiteSmoke,

            '&:not(.Mui-disabled):before': {
              borderBottom: 'unset',
            },
          },
        },
        input: {
          fontSize: 16,
          fontWeight: 400,
        },
      },
    },
  },
});

export default theme;
