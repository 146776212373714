import { Stack, TextField, Typography } from '@mui/material';
import {
  ForwardRefRenderFunction,
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';
import { isEmailValid } from '../../../../lib/form/formUtils';
import Checkbox from '../../../Checkbox/Checkbox';
import { RefFormInfo, ReferringProviderSectionInfo } from '../../form.types';

const ReferringProviderSection: ForwardRefRenderFunction<
  RefFormInfo<ReferringProviderSectionInfo>
> = (_, ref: ForwardedRef<RefFormInfo<ReferringProviderSectionInfo>>) => {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [organization, setOrganization] = useState<string>('');
  const [firstTimeReferring, setFirstTimeReferring] = useState<boolean>(false);

  const [showErrors, setShowErrors] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    getFormInfo: (): ReferringProviderSectionInfo => ({
      firstName,
      lastName,
      email,
      organization,
      firstTimeReferring,
    }),
    filledOut: (): boolean => {
      setShowErrors(true);

      return (
        firstName.trim().length > 0 &&
        lastName.trim().length > 0 &&
        isEmailValid(email) &&
        organization.trim().length > 0
      );
    },
    reset: (): void => {
      setFirstName('');
      setLastName('');
      setEmail('');
      setOrganization('');
      setFirstTimeReferring(false);

      setShowErrors(false);
    },
  }));

  return (
    <>
      <Typography variant={'h4'}>Referring Provider Information</Typography>

      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
        <TextField
          label={'First Name'}
          variant={'filled'}
          fullWidth
          required
          value={firstName}
          error={showErrors && !!!firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />

        <TextField
          label={'Last Name'}
          variant={'filled'}
          fullWidth
          required
          value={lastName}
          error={showErrors && !!!lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </Stack>

      <TextField
        label={'Email'}
        variant={'filled'}
        fullWidth
        required
        value={email}
        error={showErrors && !isEmailValid(email)}
        onChange={(e) => setEmail(e.target.value)}
      />

      <TextField
        label={'Organization'}
        variant={'filled'}
        fullWidth
        required
        value={organization}
        error={showErrors && !!!organization}
        onChange={(e) => setOrganization(e.target.value)}
      />

      <Checkbox
        label={'Is this your first time referring a patient to Enara?'}
        checked={firstTimeReferring}
        onChecked={(checked) => setFirstTimeReferring(checked)}
      />
    </>
  );
};

export default forwardRef(ReferringProviderSection);
