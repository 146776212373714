import { Stack, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import {
  ForwardRefRenderFunction,
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';
import {
  computeBMI,
  isEmailValid,
  isHeightFeetValid,
  isHeightInchesValid,
  isWeightLbsValid,
  isZipCodeValid,
} from '../../../../lib/form/formUtils';
import { PatientSectionInfo, RefFormInfo } from '../../form.types';

const PatientSection: ForwardRefRenderFunction<RefFormInfo<PatientSectionInfo>> = (
  _,
  ref: ForwardedRef<RefFormInfo<PatientSectionInfo>>
) => {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [zipCode, setZipCode] = useState<string>('');
  const [dateOfBirth, setDateOfBirth] = useState<string>('');
  const [heightFeet, setHeightFeet] = useState<string>('');
  const [heightInches, setHeightInches] = useState<string>('');
  const [weight, setWeight] = useState<string>('');

  const [showErrors, setShowErrors] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    getFormInfo: (): PatientSectionInfo => ({
      firstName,
      lastName,
      email,
      zipCode,
      dateOfBirth,
      heightFeet,
      heightInches,
      weight,
      bmi: computeBMI({ weight, feet: heightFeet, inches: heightInches }),
    }),
    filledOut: (): boolean => {
      setShowErrors(true);

      return (
        firstName.trim().length > 0 &&
        lastName.trim().length > 0 &&
        isEmailValid(email) &&
        isZipCodeValid(zipCode) &&
        dateOfBirth.trim().length > 0 &&
        isHeightFeetValid(heightFeet) &&
        isHeightInchesValid(heightInches) &&
        isWeightLbsValid(weight)
      );
    },
    reset: (): void => {
      setFirstName('');
      setLastName('');
      setEmail('');
      setZipCode('');
      setDateOfBirth('');
      setHeightFeet('');
      setHeightInches('');
      setWeight('');

      setShowErrors(false);
    },
  }));

  return (
    <>
      <Typography variant={'h4'}>Patient Information</Typography>

      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
        <TextField
          label={'First Name'}
          variant={'filled'}
          fullWidth
          required
          value={firstName}
          error={showErrors && !!!firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />

        <TextField
          label={'Last Name'}
          variant={'filled'}
          fullWidth
          required
          value={lastName}
          error={showErrors && !!!lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </Stack>

      <TextField
        label={'Email'}
        variant={'filled'}
        fullWidth
        required
        value={email}
        error={showErrors && !isEmailValid(email)}
        onChange={(e) => setEmail(e.target.value)}
      />

      <TextField
        label={'Zip Code'}
        variant={'filled'}
        fullWidth
        required
        value={zipCode}
        error={showErrors && !isZipCodeValid(zipCode)}
        onChange={(e) => setZipCode(e.target.value)}
      />

      <DatePicker
        label={'Date of Birth'}
        value={dateOfBirth}
        slotProps={{
          textField: {
            required: true,
            error: showErrors && !!!dateOfBirth,
          },
        }}
        onChange={(value) => setDateOfBirth(dayjs(value as string).format('MM-DD-YYYY'))}
      />

      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
        <TextField
          label={'Feet'}
          variant={'filled'}
          type={'number'}
          fullWidth
          required
          value={heightFeet}
          error={showErrors && !isHeightFeetValid(heightFeet)}
          onChange={(e) => setHeightFeet(e.target.value)}
        />
        <TextField
          label={'Inches'}
          variant={'filled'}
          type={'number'}
          fullWidth
          required
          value={heightInches}
          error={showErrors && !isHeightInchesValid(heightInches)}
          onChange={(e) => setHeightInches(e.target.value)}
        />
      </Stack>

      <TextField
        label={'Weight'}
        variant={'filled'}
        type={'number'}
        fullWidth
        required
        value={weight}
        error={showErrors && !isWeightLbsValid(weight)}
        onChange={(e) => setWeight(e.target.value)}
      />

      <TextField
        label={'BMI'}
        variant={'filled'}
        fullWidth
        disabled
        value={computeBMI({ weight, feet: heightFeet, inches: heightInches })}
      />
    </>
  );
};

export default forwardRef(PatientSection);
