import { TextField, Typography } from '@mui/material';
import {
  ForwardRefRenderFunction,
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';
import Checkbox from '../../../Checkbox/Checkbox';
import { RefFormInfo, WhyReferringSectionInfo } from '../../form.types';

const WhyReferringSection: ForwardRefRenderFunction<RefFormInfo<WhyReferringSectionInfo>> = (
  _,
  ref: ForwardedRef<RefFormInfo<WhyReferringSectionInfo>>
) => {
  const [patientDetails, setPatientDetails] = useState<string>('');
  const [prescribedMedicationChangesAllowed, setPrescribedMedicationChangesAllowed] =
    useState<boolean>(false);
  const [medicationChangesAllowed, setMedicationChangesAllowed] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    getFormInfo: (): WhyReferringSectionInfo => ({
      patientDetails,
      prescribedMedicationChangesAllowed,
      medicationChangesAllowed,
    }),
    filledOut: (): boolean => true,
    reset: (): void => {
      setPatientDetails('');
      setPrescribedMedicationChangesAllowed(false);
      setMedicationChangesAllowed(false);
    },
  }));

  return (
    <>
      <Typography variant={'h4'}>Why are you referring your patient to Enara Health?</Typography>

      <Typography variant={'h6'} className='wrap-text'>
        Is there anything else you would like us to know about this patient that would help in
        ensuring a timely admissions process and effective care delivery?
      </Typography>

      <TextField
        label={'Patient details'}
        variant={'filled'}
        fullWidth
        value={patientDetails}
        onChange={(e) => setPatientDetails(e.target.value)}
      />

      <Typography variant={'subtitle1'} className='wrap-text'>
        All medication changes are supervised by internal medicine physician. Our goal is to manage
        Obesity and Diabetes in conjunction with PCPs. Permission only apply to all diabetes an
        obesity related co-morbidities including depression, HTN, and Cholesterol. If referring a
        patient for obesity management, second box must be checked to give Enara physicians the
        ability to optimize medications for weight loss. We update all PCPs of any medications
        changes immediately.
      </Typography>

      <Checkbox
        label={
          <div className='wrap-text'>
            Enara Health providers are only allowed to make modifications to already prescribed
            medications. Any new prescriptions or medication replacements must be sent back to the
            PCP.
          </div>
        }
        checked={prescribedMedicationChangesAllowed}
        onChecked={(checked) => setPrescribedMedicationChangesAllowed(checked)}
      />

      <Checkbox
        label={
          <div className='wrap-text'>
            Enara Health providers are allowed to make medications modifications and/or
            prescribe/replace new medications if appropriate and update the PCP via a progress note.
          </div>
        }
        checked={medicationChangesAllowed}
        onChecked={(checked) => setMedicationChangesAllowed(checked)}
      />
    </>
  );
};

export default forwardRef(WhyReferringSection);
