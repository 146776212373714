import { Grid, LinearProgress, Typography } from '@mui/material';
import { FC } from 'react';

const PreparingForm: FC = () => {
  return (
    <Grid
      container
      direction='column'
      alignItems='center'
      justifyContent='center'
      sx={{ minHeight: '100vh' }}>
      <Grid>
        <Typography variant={'h4'}>Preparing form</Typography>

        <LinearProgress />
      </Grid>
    </Grid>
  );
};

export default PreparingForm;
