import { FormControlLabel, Checkbox as MuiCheckbox } from '@mui/material';
import { FC, ReactNode } from 'react';
import './scss/checkbox.scss';

type Props = {
  label: ReactNode;
  checked: boolean;
  onChecked: (checked: boolean) => void;
};

const Checkbox: FC<Props> = ({ label, checked, onChecked }) => {
  return (
    <FormControlLabel
      className={checked ? 'checked' : 'unchecked'}
      control={<MuiCheckbox onChange={(e) => onChecked(e.target.checked)} />}
      label={label}
      componentsProps={{ typography: { fontWeight: 700, overflow: 'hidden' } }}
    />
  );
};

export default Checkbox;
