import { Stack, Typography } from '@mui/material';
import { FC, useRef, useState } from 'react';
import onboardingClient from '../../lib/onboarding/client';
import BasicLayout from '../BasicLayout/BasicLayout';
import CreateLeadPrompt from '../CreateLeadPrompt/CreateLeadPrompt';
import PatientSection from './Sections/PatientSection/PatientSection';
import ReferringProviderSection from './Sections/ReferringProviderSection/ReferringProviderSection';
import WhyReferringSection from './Sections/WhyReferringSection/WhyReferringSection';
import {
  LeadCaptureFormStatus,
  PatientSectionInfo,
  RefFormInfo,
  ReferringProviderSectionInfo,
  WhyReferringSectionInfo,
} from './form.types';

const LeadCaptureForm: FC = () => {
  const [isSaving, setIsSavings] = useState<boolean>(false);
  const [formStatus, setFormStatus] = useState<LeadCaptureFormStatus>({
    isPromptOpen: false,
    promptMessage: '',
    status: 'none',
  });

  const referringProviderRef = useRef<RefFormInfo<ReferringProviderSectionInfo>>(null);
  const patientRef = useRef<RefFormInfo<PatientSectionInfo>>(null);
  const whyReferringRef = useRef<RefFormInfo<WhyReferringSectionInfo>>(null);

  const handleCreateLead = async (): Promise<void> => {
    const referringSectionFilledOut = referringProviderRef.current!.filledOut();
    const patientSectionFilledOut = patientRef.current!.filledOut();

    if (!referringSectionFilledOut || !patientSectionFilledOut) {
      return;
    }

    const referringInfo = referringProviderRef.current!.getFormInfo();
    const patientInfo = patientRef.current!.getFormInfo();
    const whyReferringInfo = whyReferringRef.current!.getFormInfo();

    setIsSavings(true);

    if (await onboardingClient.createLead({ referringInfo, patientInfo, whyReferringInfo })) {
      setFormStatus({
        isPromptOpen: true,
        promptMessage: 'Patient was successfully referred!',
        status: 'success',
      });
    } else {
      setFormStatus({
        isPromptOpen: true,
        promptMessage: 'There was an error trying to refer this patient',
        status: 'with-errors',
      });
    }

    setIsSavings(false);
  };

  const handleClosePrompt = (): void => {
    if (formStatus.status === 'success') {
      referringProviderRef.current!.reset();
      patientRef.current!.reset();
      whyReferringRef.current!.reset();
    }

    setFormStatus({ isPromptOpen: false, promptMessage: '', status: 'none' });
  };

  return (
    <>
      <BasicLayout
        title={'Provider Referrals'}
        subtitle={
          <>
            <Typography variant={'h4'} className='wrap-text'>
              Enara Health welcomes the opportunity to partner with you to provide your patients
              with a compassionate, science based, holistic obesity-management program.
            </Typography>
          </>
        }
        buttonProps={{
          text: 'Submit',
          loading: isSaving,
          disabled: isSaving,
          inForm: true,
          onClick: handleCreateLead,
        }}>
        <Stack spacing={2}>
          <ReferringProviderSection ref={referringProviderRef} />

          <PatientSection ref={patientRef} />

          <WhyReferringSection ref={whyReferringRef} />
        </Stack>
      </BasicLayout>

      <CreateLeadPrompt
        isOpen={formStatus.isPromptOpen}
        content={formStatus.promptMessage}
        onClose={handleClosePrompt}
      />
    </>
  );
};

export default LeadCaptureForm;
